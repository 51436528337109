import React, { useContext, useState } from 'react';
import { ReferenceContext } from '../contexts/ReferenceContext'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './Bibliography.css';

const Bibliography = () => {
    const { references } = useContext(ReferenceContext);
    const [selectedRefs, setSelectedRefs] = useState([]);
    const [expandAll, setExpandAll] = useState(false);
    const [viewMode, setViewMode] = useState('Curated');

    let refsArrayAll = Object.values(references);
    let refsArray = Object.values(references);
    if (viewMode === 'Curated') {
        refsArray = refsArray.filter(ref => ref.seqRef != null);
    }

    refsArray.sort((a, b) => {
        if (a.seqRef != null && b.seqRef != null) {
            // both have seqRef, sort by seqRef
            return a.seqRef - b.seqRef;
        } else if (a.seqRef != null) {
            // only a has seqRef, a comes first
            return -1;
        } else if (b.seqRef != null) {
            // only b has seqRef, b comes first
            return 1;
        } else {
            // neither has seqRef, maintain original order
            return 0;
        }
    });

    const handleToggleViewMode = () => {
        setViewMode(viewMode === 'Curated' ? 'All' : 'Curated');
    };

    const handleClickReference = (hexRef) => {
        //console.log("Handle click reference")
        //console.log(selectedRefs)
        setSelectedRefs((selectedRefs) => {
            if (selectedRefs.includes(hexRef)) {
                return selectedRefs.filter(ref => ref !== hexRef);
            } else {
                return [...selectedRefs, hexRef];
            }
        });
    };

    const handleExpandCollapseAll = () => {
        //console.log("Handle expand collapse all")
        setExpandAll(prevExpandAll => {
            setSelectedRefs(prevExpandAll ? [] : refsArray.map(ref => ref.hexRef));
            return !prevExpandAll;
        });
    };
    

    return (
        <div className="bibliography">
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {refsArrayAll.length > 0 && (
                    <span>Showing: <button onClick={handleToggleViewMode}>{viewMode} references</button></span>
                )}
                {refsArray.length > 0 && (
                    <button onClick={handleExpandCollapseAll}>{expandAll ? 'Collapse All' : 'Expand All'}</button>
                )}
            </div>
            <ul className="referenceList">
            {refsArray.map(ref => (
            <li key={ref.hexRef} onClick={() => handleClickReference(ref.hexRef)}>
                <span className={selectedRefs.includes(ref.hexRef) ? 'expanded-ref' : ''}>
                <span className="referenceNumber">{ref.seqRef ? ref.seqRef : '?'}</span> <i>{ref.act}</i> ({ref.jurisdiction}){ref.schedule && ` ${ref.schedule}` }{ref.section && ` s ${ref.section}` }. 
                </span>
                {selectedRefs.includes(ref.hexRef) && <p className="reference-content">{ref.content}</p>}
            </li>
            ))}
            </ul>
        </div>
    );
};

// 
// <ReactMarkdown remarkPlugins={[remarkGfm]} children={ref.content} />

export default Bibliography;
