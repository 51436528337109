import { useContext, useEffect } from 'react';
import { ReferenceContext} from '../contexts/ReferenceContext';

import './Citation.css';

const Citation = ({ children }) => {
  const { queryReference } = useContext(ReferenceContext);

  const ref = queryReference(children)

  const title = ref?.title ?? "..";
  const content = ref?.content ?? "..";
  const seqRef = ref?.seqRef ?? "..";

  const act = ref?.act ?? "..";
  const jurisdiction = ref?.jurisdiction ?? "..";
  const schedule = ref?.schedule ?? "..";
  const section = ref?.section ?? "..";

  const formattedTitle = `${act} (${jurisdiction})` + (schedule !== '..' ? ` ${schedule}` : '') + (section !== '..' ? `  s ${section}` : '') + '.'

  return (
      <span className={title !== ".." ? 'citation' : 'citation-failed'} title={formattedTitle + '\n' + content}>{seqRef}</span>
  );
};

export default Citation;



