import { useState, useRef, useEffect, useContext } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { ReferenceContext} from '../contexts/ReferenceContext';
import ChatInput from './ChatInput';
import ChatHistory from './ChatHistory';
import Bibliography from './Bibliography';


class RetriableError extends Error { }
class FatalError extends Error { }

const ChatConversation = ({historyId, onUpdateSummaryTitle, onUpdateHistoryId}) => {
  const { addReferences, updateReference, queryReference, clearReferences } = useContext(ReferenceContext);

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const addMessage = (state, parentId, newMessage) => {
    const addMessageToTree = (tree, parentId, newMessage) => {
      if (tree.id === parentId) {
        return { ...tree, children: [...tree.children, newMessage], };
      }
  
      const updatedChildren = tree.children.map((child) =>
        addMessageToTree(child, parentId, newMessage)
      );
  
      return { ...tree, children: updatedChildren, };
    };
  
    if (parentId === null) {
      return [...state, newMessage];
    }
  
    const updatedMessages = state.map((message) =>
      addMessageToTree(message, parentId, newMessage)
    );
  
    return updatedMessages;
  };

  const sendChatMessage = async (userInput) => {
    setIsButtonDisabled(true);
    let role = null;

    setNewMessage({sender: "user", content: userInput, children: []});

    let sentMessageId = null;
    let finishReason = null;
    let assistantReply = "";

    await fetchEventSource(`/a/chat/sendMessage`, {
      method: "POST",
      openWhenHidden: true,
      headers: {
        "Accept": "text/event-stream",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "parentMessageId": lastMessageId,
        "content": userInput
      }),
      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        if(event.data === "DONE") {
            return;
        }
        //console.log(event.data);
        const parsedData = JSON.parse(event.data);

        switch(parsedData.type) {
          case 'ERROR':
            console.log('Error: ' + parsedData.message);
            throw FatalError;
            break;
          case 'SUMMARY_TITLE':
            onUpdateSummaryTitle(parsedData.summaryTitle);
            break;
          case 'CHAT_HISTORY_STORED':
            onUpdateHistoryId(parsedData.chatHistoryId);
            break;
          case 'USER_MESSAGE_STORED':
            setMessages(m => addMessage(m, parsedData.parentMessageId ?? null, {
                id: parsedData.messageId,
                sender: "user",
                sender_uuid: parsedData.senderId,
                content: userInput,
                children: []
            }));
            setNewMessage({sender: "assistant", content: "", children: []});
            break;
          case 'ATTACHMENTS_STORED':
            addReferences(parsedData.attachments);
            break;
          case 'ATTACHMENT_UPDATED':
            updateReference(parsedData.hexRef, parsedData.seqRef);
            break;
          case 'ASSISTANT_TEXT':
            assistantReply += parsedData.text;
            setNewMessage({sender: "assistant", content: assistantReply, children: []})
            if(parsedData.finishReason) { finishReason = parsedData.finishReason; }
            break;
          case 'ASSISTANT_MESSAGE_STORED':
            setMessages((m) => addMessage(m, sentMessageId, {
                id: parsedData.messageId,
                sender: "assistant",
                content: assistantReply,
                children: []
            }));
            setNewMessage(null);
            break;
          default:
            console.log("Unexpected message:");
            console.log(parsedData);
        }
      },
      onclose() {
        console.log("Connection closed by the server");
        setIsButtonDisabled(false);
      },
      onerror(err) {
        console.log("There was an error from server", err);
        setIsButtonDisabled(false);
        // TODO: Handle error
        throw new FatalError("sendChatMessage failed");
      },
    });
  };

  const handleSubmitInput = (userInput) => {
    sendChatMessage(userInput);
    // Scroll the window back to the bottom
    setTimeout(() => {
      window.scroll({ top: 0, behavior: 'smooth' });
    }, 50);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [newMessage]);

  useEffect(() => {
    async function fetchChatData() {
      if(!historyId) {
        setMessages([]);
        clearReferences();
        setLastMessageId(null);
      } else {
        try {
          const response = await fetch(`/a/chat/getHistory?chatHistoryId=${historyId}`);
          const data = await response.json();
          if (data.success) {
            const { messages: receivedMessages } = data.chatHistory;        
            setMessages(receivedMessages);
            clearReferences();
            addReferences(data.attachments);
          } else {
            setMessages([]);
            clearReferences();
            setLastMessageId(null);
          }
     
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchChatData();


  }, [historyId, addReferences, clearReferences]);


  const handleMessageUpdate = (messageId) => {
    setLastMessageId(messageId);
  }

  return (
    <div className="chat-container">
      <ChatHistory chatMessages={messages} newMessage={newMessage} messagesEndRef={messagesEndRef} onMessageUpdate={handleMessageUpdate} />
      <Bibliography />
      <ChatInput isButtonDisabled={isButtonDisabled} handleSubmitInput={handleSubmitInput} />
      <p class="chat-caveat">Based on OpenAI GPT. May produce inaccurate information about people, places or facts.</p>
    </div>
  );

  
};

export default ChatConversation;
