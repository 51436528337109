import { useContext } from 'react';
import { ReferenceContext} from '../contexts/ReferenceContext';
import './Citation.css';

const BadCitation = ({ children }) => {
  return (
      <span className='citation-failed' title={"Warning: Unreliable statement or source: " + children}>⚠️</span>
  );
};

export default BadCitation;

