import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BadCode = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timeoutId); // Clear the timeout if the component unmounts

  }, [navigate]);

  return (
    <div>
      <h2>Sorry</h2>
      <p>That link is invalid or has expired. Please try again or contact <a href="mailto:support@matilda.law">support@matilda.law</a> for assistance.</p>
      <p>Redirecting to the <a href="/">main page</a>...</p>
    </div>
  );
};

export default BadCode;
