import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import Chat from "./components/Chat";
import Login from "./components/Login";
import BadCode from "./components/BadCode";
import axios from 'axios';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogout = () => {
    setIsLoggedIn(false)
  }

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const { state } =useLocation();
  const logoutMessage = state?.message;

  // Query the server to see if the user is actually logged in, and use that instead of a state variable

  const checkIfLoggedIn = async() => {
    try {
      // Send a GET request to the /authPing endpoint
      const response = await axios.get('/auth/ping', {'timeout': 5000});
      console.log(response.data)
      if (response.data.success) {
        handleLogin();
        console.log('Logged in');
      } else {
        handleLogout();
      }
    } catch (error) {
      handleLogout();
    }    
  }

  useEffect(() => {
    console.log('Checking if logged in already')
    checkIfLoggedIn();
  }, []);

  /*
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/chat" />}/>
        <Route path="/chat" element={<Chat />}/>
        <Route path="/chat/:historyId" element={<Chat />}/>
      </Routes>
    </div>
  )

  */

  // TODO: Style logout message
  return (
    <div>
      {logoutMessage && <p>{logoutMessage}</p>}
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate to="/chat" /> : <Login onLogin={handleLogin} />}
        />
        <Route
          path="/chat"
          element={isLoggedIn ? <Chat onLogout={handleLogout} /> : <Navigate to="/" />}
        />
        <Route path="/chat/:historyId"
          element={isLoggedIn ? <Chat onLogout={handleLogout} /> : <Navigate to="/" />}
        />
        <Route path="/badcode"
          element={<BadCode/>}
        />
      </Routes>
    </div>
  );

};

export default App;
