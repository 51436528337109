import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './MenuHistories.css';

const MenuHistories = ({ handleLogout, isMenuOpen, handleNewChat, chatHistory }) => {
  const [chatHistories, setChatHistories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchChatHistories() {
      try {
        const response = await fetch('/a/chat/getHistories');
        const data = await response.json();
        if (data.type === "CHAT_HISTORIES") {
          setChatHistories(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchChatHistories();
  }, []);

  function handleNewChatClick() {
    handleNewChat();
    navigate(`/chat/`);
  }

  function handleHistoryClick(historyId) {
    navigate(`/chat/${historyId}`);
  }

  useEffect(() => {
    // The chat has just been given a chat ID. Add it at the top of the history
    if (chatHistory.id && !chatHistories.some(ch => ch.id === chatHistory.id)) {
      setChatHistories(prevChatHistories => [{...chatHistory, summaryTitle: chatHistory.summaryTitle ?? "New chat"}, ...prevChatHistories]);
      return;
    }
    // The chat has just been given a summary title. Set it in the menu.
    if (chatHistory.id && chatHistories[0]?.id === chatHistory.id) {
      if(chatHistory.summaryTitle && chatHistory.summaryTitle !== chatHistories[0].summaryTitle) {
        setChatHistories(prevChatHistories => [chatHistory, ...chatHistories.slice(1)]);
      }
    }
  }, [chatHistory, chatHistories]);

              //className={(history.id === activeHistoryId || history.id === chatHistory.id) ? 'current-history' : ''}
  return (
    <div className="menu-histories">
      <ul>
        <li key="new">
          <button onClick={handleNewChatClick} >New chat</button>
        </li>
        {chatHistories.map(history => (
          <li key={history.id}>
            <button
              onClick={() => handleHistoryClick(history.id)}
              className={(history.id === chatHistory.id) ? 'current-history' : ''}
            >
              {history.summaryTitle}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MenuHistories;

