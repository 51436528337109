import React, { createContext, useState, useCallback } from 'react';

const ReferenceContext = createContext();

const ReferenceProvider = ({ children }) => {
  // Initialize state
  const [references, setReferences] = useState({});

  const splitTitle = (str) => {
    const jurisdictions = {
      "Commonwealth of Australia": "Cth",
      "New South Wales": "NSW",
      "Victoria": "Vic",
      "South Australia": "SA",
      "Western Australia": "WA",
      "Tasmania": "Tas",
      "Queensland": "Qld",
      "Northern Territory": "NT",
      "Australian Capital Territory": "ACT"
    };

    let parts = str.split(' > ').filter(function(part) {
      return part !== '';
    });
    if(parts.length <= 1) {
        return { act: '..', jurisdiction: '..', schedule: undefined, section: '..' }
    }
    console.log(parts)
    let jurisdiction = jurisdictions[parts[0]];
    let act = parts[1].trim();
    let section;
    let lastPart = parts[parts.length - 1].trim();
    console.log(lastPart)
    let sectionRe = /^Section \S+\./;

    if(sectionRe.test(lastPart)) {    
      section = parts[parts.length-1].trim().split(' ')[1].replace('.', '');
    }
    let schedule = parts.find(part => part.toLowerCase().startsWith("schedule"));

    return { act, jurisdiction, schedule, section }
  }

  // Add a single reference
  const addReference = useCallback((hexRef, title, content, seqRef) => {
    const newReference = { title, content, seqRef, hexRef, ...splitTitle(title) };
    setReferences(prevState => ({ ...prevState, [hexRef]: newReference }));
  }, []);

  // Add multiple references
  const addReferences = useCallback((refs) => {
    setReferences(prevState => ({
      ...prevState,
      ...refs.reduce((acc, { title, content, seqRef, hexRef }) => {
        acc[hexRef] = { title, content, seqRef, hexRef, ...splitTitle(title) };
        return acc;
      }, {}),
    }));
  }, []);

  const updateReference = useCallback((hexRef, seqRef) => {
    setReferences((currentReferences) => {
      const reference = currentReferences[hexRef];

      if (!reference) {
        return currentReferences;
      }

      return {
        ...currentReferences,
        [hexRef]: { ...reference, seqRef },
      };
    });
  }, []);

  // Query a reference
  const queryReference = useCallback((hexRef) => {
    return references[hexRef];
  }, [references]);

  // Clear references
  const clearReferences = useCallback(() => {
    setReferences({});
  }, []);

  return (
    <ReferenceContext.Provider
      value={{
        references,
        addReference,
        addReferences,
        updateReference,
        queryReference,
        clearReferences
      }}
    >
      {children}
    </ReferenceContext.Provider>
  );
};

export { ReferenceContext, ReferenceProvider };
