import React from 'react';

const ChatInput = ({ isButtonDisabled, handleSubmitInput }) => {
  const [value, setValue] = React.useState('');
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = '20px'; // Minimum height
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (!event.shiftKey && !value.includes('\n')) { // Submit only if Shift is not pressed and there are no newlines in the value
        event.preventDefault();
        handleSubmitInput(value);
        setValue(''); // clear the input field
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSubmitInput(value);
    setValue(''); // clear the input field
  };

  return (
    <form onSubmit={handleSubmit} className="chat-input">
      <textarea ref={inputRef} value={value} onChange={handleChange} onKeyDown={handleKeyDown} className="input-field" />
      <button disabled={isButtonDisabled} className="submit-button">
        Send
      </button>
    </form>
  );
};

export default ChatInput;
