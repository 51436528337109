import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkDirective from 'remark-directive';
import { visit } from "unist-util-visit";
import remarkGfm from 'remark-gfm';
import Citation from './Citation';
import BadCitation from './BadCitation';

const ChatMessage = ({ message, siblingCount, selectedSiblingIndex, onSiblingLeft, onSiblingRight, onMessageUpdate }) => {
  const [selectedChildIndex, setSelectedChildIndex] = useState(message.children.length - 1);

  useEffect(() => {
    setSelectedChildIndex(message.children.length - 1);
  }, [message]);

  useEffect(() => {
    if (onMessageUpdate && !message.children.length) {
      onMessageUpdate(message.id);
    }
  }, [message, onMessageUpdate]);

  const childrenCount = message.children.length;
  const hasChildren = childrenCount > 0;

  const selectedChild = hasChildren ? message.children[selectedChildIndex] : null;

  const handlePrevSibling = () => {
    if(onSiblingLeft) {
      onSiblingLeft();
    }
  };

  const handleNextSibling = () => {
    if(onSiblingRight) {
      onSiblingRight();
    }
  };
 
  const reactMarkdownRemarkDirective = () => {
    return (tree) => {
      visit(
        tree,
//        ["textDirective", "leafDirective", "containerDirective"],
        ["textDirective"],
        (node) => {
          node.data = {
            hName: node.name,
            hProperties: node.attributes,
            ...node.data
          };
          return node;
        }
      );
    };
  }

  //const content = parseCitations(message.content)

  return (
    <>
      <div className={`message message-${message.sender}`}>
        <ReactMarkdown remarkPlugins={[remarkGfm, remarkDirective, reactMarkdownRemarkDirective]} components={{ Citation, BadCitation }} children={message.content} />
        {siblingCount > 1 &&  (
          <div className="message-controls">
            <button disabled={selectedSiblingIndex === 0} onClick={handlePrevSibling}>
              &larr;
            </button><span>{selectedSiblingIndex + 1}/{siblingCount}</span>
            <button disabled={selectedSiblingIndex === siblingCount - 1} onClick={handleNextSibling}>
              &rarr;
            </button>
          </div>
        )}
      </div>
      {selectedChild && (
        <ChatMessage message={selectedChild}
                      siblingCount={childrenCount}
                      selectedSiblingIndex={selectedChildIndex}
                      onSiblingLeft={ () => setSelectedChildIndex((prevIndex) => prevIndex - 1)}
                      onSiblingRight={() => setSelectedChildIndex((prevIndex) => prevIndex + 1)}
                      onMessageUpdate={onMessageUpdate}
        />
      )}
    </>
  );
};

export default ChatMessage;
