const MenuItems = ({ handleLogout }) => {

  return (
    <div className="menu-items">
      <button className="logout-button" onClick={handleLogout}>
        Log out
      </button>
    </div>
  );
};

export default MenuItems;

