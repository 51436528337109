import { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { ReferenceProvider} from '../contexts/ReferenceContext';
import Menu from './Menu';
import ChatConversation from './ChatConversation';
import Modal from 'react-modal';
import axios from 'axios';

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root');

const Chat = ({ onLogout }) => {
  const addressBarHistoryId = useParams().historyId;

  const [isMenuOpen,  setIsMenuOpen]  = useState(false);
  const [chatHistory, setChatHistory] = useState({ });
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const welcomeMessages = [
    <>
    <h3>Hi! I'm Matilda, an AI-powered legal research tool for Australian law</h3>
    <p><ul><li>I provide plain English answers to legal questions</li>
           <li>I efficiently navigate the complex world of law</li>
           <li>I summarise, explain and apply knowledge</li>
       </ul>
    </p></>,
    <><h3>My strengths:</h3>
    <p><ul><li>I draw on Western Australian and Commonwealth legislation</li>
           <li>I cite my sources</li>
           <li>I adapt my writing style as needed</li>
       </ul>
    </p></>,
    <><h3>My limitations:</h3>
    <p><ul><li>I do not yet have access to case law</li>
           <li>I'm not a lawyer; treat me like a legal research tool</li> 
           <li>I may provide inaccurate or incomplete info; verify accuracy</li>
           <li>See my terms of use for limits on liability & legal recourse</li>
       </ul>
    </p></>
  ];

  const nextModal = () => {
    if(step < welcomeMessages.length - 1) {
      setStep(step + 1);
    } else {
      setModalIsOpen(false);
    }
  };

  if(addressBarHistoryId && (addressBarHistoryId !== chatHistory.id)) {
    setChatHistory(c => { return {...c, id: addressBarHistoryId} });
    console.log("Set historyId");
  }

  // We need this for the case where the New chat button is pressed
  // but the address bar is already at /chat/, so we can't rely on
  // browser navigation to trigger a change in the addressBarHistoryId
  const handleNewChat = () => {
    setChatHistory(c => { return {...c, id: null} });
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen(false);
  };

  const handleToggleMenu = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      // Send a POST request to the /logout endpoint
      axios.post('/auth/logout', { }, {'timeout': 5000}).then(response => {
        // If the logout was successful, call the onLogout() function to log out of the frontend
        if (response.data.success) {
          onLogout();
        } else {
          // Display an error message if the login was not successful
          alert(`Logout failed. ${response.data.error}`);
        }
      });
    } catch (error) {
      alert('Logout failed due to a system error. Please try again later or contact us directly.');
      return ({ success: false, error: 'System error' });
    }
  };

  const onUpdateSummaryTitle = (summaryTitle) => {
    setChatHistory(c => { return {...c, summaryTitle: summaryTitle }; });
  };

  const onUpdateHistoryId = (id) => {
    setChatHistory(c => { return {...c, id: id} });
  };

  return (
    <div className="ui-container">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Welcome"
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'unset',
            width: '66%',
            inset: 'unset',
          },
        }}
       >
        {welcomeMessages[step]}
        <div style={{ textAlign: 'right' }}>
          <button onClick={nextModal}>
            {step < welcomeMessages.length - 1 ? 'Next' : 'Got it!'}
          </button>
        </div>
      </Modal>
      <div className={`menu-hamburger${isMenuOpen ? ' menu-open' : ''}`}>
        <span style={{"cursor": "default"}} onClick={handleToggleMenu} >&#x2630;</span>
      </div>
      <Menu isMenuOpen={isMenuOpen} handleLogout={handleLogout} handleNewChat={handleNewChat} chatHistory={chatHistory} />
      <div className={`menu-overlay${isMenuOpen ? ' menu-open' : ''}`} onClick={handleCloseMenu}> 
        <ReferenceProvider>
           <ChatConversation historyId={chatHistory.id} onUpdateSummaryTitle={onUpdateSummaryTitle} onUpdateHistoryId={onUpdateHistoryId} />
        </ReferenceProvider>
      </div>
    </div>
  );
  
};

export default Chat;
