import React, { useState, useEffect } from "react";
import axios from 'axios';

import './Login.css';

const Login = ({ onLogin }) => {
  const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState(null);

  const handleSwitch = () => {
    setIsSignUp(!isSignUp);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);  

    if(isSignUp) {
      // Free trial. Only the email is specified.
      const email = e.target.email.value;

      const signUpResponse = callSignUpApi(email);

      if(signUpResponse.success) {
        onLogin();
      } else {
        setError(signUpResponse.error);
      }
    } else {
      // 
      // Get the email and password from the input fields
      const email = e.target.email.value;
      const password = e.target.password.value;

      const loginResponse = await callLoginApi(email, password);

      if(loginResponse.success) {
        onLogin();
      } else {
        setError(loginResponse.error);
      }
    }
  }
 
  const callLoginApi = async (email, password) => { 
    try {
      // Send a POST request to the /login endpoint with the email and password
      const response = await axios.post('/auth/login/password', { email, password }, {'timeout': 5000});

      // If the login was successful, call the onLogin function to set the front-end state
      if (response.data.success) {
        onLogin();
        return ({ success: true });
      } else {
        // Display an error message if the login was not successful
        alert(`${response.data.error}`);
        return ({ success: false, error: `Login failed. ${response.data.error}` });
      }
    } catch (error) {
      alert('Login failed due to a system error. Please try again later or contact us directly.');
      return ({ success: false, error: 'Login failed due to a system error. Please try again later or contact us directly.' });
    }
  }

  const callSignUpApi = async (email) => { 
    try {
      // Send a POST request to the /signUp endpoint with the email
      const response = await axios.post('/auth/signUp', { email }, {'timeout': 5000});
      // If the login was successful, call the onLogin function to set the front-end state
      if (response.data.success) {
        onLogin();
        return ({ success: true });
      } else {
        // Display an error message if the sign up was not successful
        alert(`${response.data.error}`);
        return ({ success: false, error: `Sign up failed. ${response.data.error}` });
      }
    } catch (error) {
      console.log(error);
      alert(`Sorry, there's been a system error. Please try again later or contact us directly.`);
      return ({ success: false, error: 'Sign up failed due to a system error. Please try again later or contact us directly.' });
    }
  }


  return (
    <div className="container">
      <div className="auth-section">
        <div className="logo">
          <img src="images/logo.jpg" alt="Logo" className="logo-img" />
        </div>
        <div className="login-box">
          <h1 className="auth-title">matilda.law</h1>
          <form onSubmit={handleSubmit} className="auth-form">
            <div className="input-group">
              <label htmlFor="email" className="input-label">Email:</label>
              <input type="email" id="email" name="email" className="input-field" />
            </div>
            {!isSignUp && (
              <div className="input-group">
                <label htmlFor="password" className="input-label">Password:</label>
                <input type="password" id="password" name="password" className="input-field" />
              </div>
            )}
            <button type="submit" className="submit-btn">{isSignUp ? "Free Trial" : "Log In"}</button>
            {isSignUp && (
              <p className="signup-fineprint">Usage limits apply. We may contact you at this email address regarding your trial experience.</p>
            )}
          </form>
          <p className="switch-text">
            {isSignUp
              ? "Already have an account? "
              : "Don't have an account yet? "}
            <button onClick={handleSwitch} className="switch-btn">
              {isSignUp ? "Log In" : "Free Trial"}
            </button>
          </p>
        </div>
      </div>
      <div className="features-section">
        <div className="features-text">
          <h2 className="features-title">Matilda's key strengths</h2>
          <p className="features-description">
          Matilda is an Australian AI tool designed to streamline legal procedures by 
          integrating multiple data sources, summarising complex laws, and drafting 
          precise correspondence. With Matilda, uncover overlooked legislative provisions, 
          enhance the accuracy of your copy, and receive confidential feedback, all while 
          saving time and fostering creativity.
          </p>
        </div>
        <div className="features-icons">
          <div className="icon-row">
            <div className="icon-card">
              <div className="icon">Use AI Safely</div>
              <ul className="icon-points">
                <li>Addresses AI over-confidence</li>
                <li>Integrated citations and references</li>
              </ul>
            </div>
            <div className="icon-card">
              <div className="icon">Enhance Productivity</div>
              <ul className="icon-points">
                <li>Brainstorm ideas</li>
                <li>Draft and review quickly</li>
                <li>Receive impartial feedback</li>
              </ul>
            </div>
          </div>
          <div className="icon-row">
            <div className="icon-card">
              <div className="icon">Tailored to Australia</div>
              <ul className="icon-points">
                <li>Commonwealth and State sources</li>
                <li>Legislation and regulations</li>
                <li>Better privacy</li>
              </ul>
            </div>
            <div className="icon-card">
              <div className="icon">Work Differently</div>
              <ul className="icon-points">
                <li>A trusted partner</li>
                <li>Social without interruption</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Login;
