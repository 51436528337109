import React from 'react';
import ChatMessage from './ChatMessage';

const ChatHistory = ({ chatMessages, newMessage, messagesEndRef, onMessageUpdate }) => {
  if (!chatMessages) {
    return <div className="chat-history" />;
  }

  return (
    <div className="chat-history">
      {chatMessages.map((item, index) => (        
        <ChatMessage key={index} message={item} onMessageUpdate={onMessageUpdate} />
      ))}
      {newMessage && (
         <ChatMessage key="new" message={newMessage} /> 
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatHistory;
