import MenuHistories from './MenuHistories';
import MenuItems from './MenuItems';

const Menu = ({ handleLogout, isMenuOpen, handleNewChat, chatHistory }) => {

  return (
    <div className={`menu${isMenuOpen ? ' menu-open' : ''}`} >
      <div className="menu-inner">
        <MenuHistories handleNewChat={handleNewChat} chatHistory={chatHistory} />
        <MenuItems handleLogout={handleLogout} />
      </div>
    </div>
  );
};

export default Menu;

